import React, { useState, createContext, useEffect, useContext } from "react";
import {
  getRayzeAccounts,
  getRayzeAccountTransactions,
  getWalletStripeAccount,
} from "services/wallet-service";

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [rayzeWalletStripeAccount, setRayzeWalletStripeAccount] =
    useState(null);
  const [getWalletAccounts, setGetWalletAccounts] = useState(false);
  const [walletAccounts, setWalletAccounts] = useState([]);
  const [allWalletAccounts, setAllWalletAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [walletTransactions, setWalletTransactions] = useState([]);
  const [sorting, setSorting] = useState({
    orderBy: "balance",
    orderIn: "desc",
  });

  useEffect(() => {
    if (!getWalletAccounts) return;

    let sub = getRayzeAccounts(setWalletAccounts);

    return () => {
      sub();
    };
  }, [getWalletAccounts]);

  useEffect(() => {
    getWalletStripeAccount(setRayzeWalletStripeAccount);
    if (!selectedAccount) return;
    const findIndex = walletAccounts.findIndex(
      (f) => f.id === selectedAccount.id
    );
    if (findIndex !== -1) {
      setSelectedAccount(walletAccounts[findIndex]);
    }
  }, [walletAccounts]);

  useEffect(() => {
    let sorted = [...walletAccounts];
    //sorting
    if (sorting.orderBy && sorting.orderIn) {
      sorted = sorted.sort((a, b) => {
        let valA = a[sorting.orderBy] || 0;
        let valB = b[sorting.orderBy] || 0;

        if (valA < valB) {
          return sorting.orderIn === "asc" ? -1 : 1;
        }
        if (valA > valB) {
          return sorting.orderIn === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    setAllWalletAccounts(sorted);
  }, [walletAccounts, sorting]);

  useEffect(() => {
    if (!selectedAccount) return;

    // listen to transactions
    let sub = getRayzeAccountTransactions(
      selectedAccount,
      setWalletTransactions
    );

    return () => {
      sub();
    };
  }, [selectedAccount]);

  return (
    <WalletContext.Provider
      value={{
        rayzeWalletStripeAccount,
        getWalletAccounts,
        setGetWalletAccounts,
        walletAccounts,
        allWalletAccounts,
        selectedAccount,
        setSelectedAccount,
        walletTransactions,
        sorting,
        setSorting,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
